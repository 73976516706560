<template>
  <div v-if="items.length">
    <div v-if="isInProgress" class="block">
      Importing: {{ progressPercentage }}%
    </div>
    <div v-else class="block">
      Ready
    </div>
    <progress class="progress" :value="handledCount" :max="items.length"></progress>
    <div class="level">
      <div class="level-item has-text-centered">
        <div>
        <p class="heading has-text-success">Success</p>
        <p class="title">{{ successCount }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
        <p class="heading has-text-warning">Warnings</p>
        <p class="title">{{ warningCount }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
            <p class="heading has-text-danger">Failures</p>
            <p class="title ">{{ failureCount }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WorkoutImportStatus',
  props: ['items'],
  computed: {
    progressPercentage() {
      return Math.round((this.handledCount / this.items.length) * 100);
    },
    isInProgress() {
      return this.handledCount !== this.items.length;
    },
    successCount() {
      return this.items.reduce((prevValue, current) => (
        current.status === 'success' ? prevValue + 1 : prevValue
      ), 0);
    },
    failureCount() {
      return this.items.reduce((prevValue, current) => (
        current.status === 'error' ? prevValue + 1 : prevValue
      ), 0);
    },
    warningCount() {
      return this.items.reduce((prevValue, current) => (
        current.status === 'warning' ? prevValue + 1 : prevValue
      ), 0);
    },
    handledCount() {
      return this.items.reduce((prevValue, current) => (
        current.status !== 'pending' ? prevValue + 1 : prevValue
      ), 0);
    },
  },
};
</script>
