<template>
  <section class="app-content">
    <h1 class="title">Upload</h1>
    <div v-if="isUploading" class="box">
      <div class="section has-text-centered">
        Please wait...
      </div>
    </div>
    <div v-else class="box" @drop.prevent @dragover.prevent @drop="onDrop">
      <div class="section has-text-centered is-loading">
        Drag&Drop files here or <a @click="triggerFileInput">browse files</a>
      </div>
      <input class="is-hidden" type="file" ref="fileInput" multiple @change="onInputChange"/>
    </div>
    <WorkoutImportStatus :items="filesData" />
    <table v-if="filesData.length" class="table is-striped is-fullwidth">
      <thead>
        <tr>
          <th>File Name</th>
          <th>File Size</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-bind:class="{
            'has-text-success': file.status === 'success',
            'has-text-warning': file.status === 'warning',
            'has-text-danger': file.status === 'error',
          }"
          v-for="(file, key) in filesData"
          :key="key"
        >
          <td> {{ file.name }} </td>
          <td> {{ filesize(file.size) }} </td>
          <td v-if="file.errorMessage">{{ file.status }}: {{file.errorMessage}}</td>
          <td v-else>{{ file.status }} </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import WorkoutImportStatus from '@/components/WorkoutImportStatus.vue';
import filesize from 'filesize/lib/filesize.es6.min';

export default {
  name: 'Upload',
  components: {
    WorkoutImportStatus,
  },
  data() {
    return {
      filesData: [],
      isUploading: false,
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onDrop(event) {
      this.handleUpload(event.dataTransfer.files);
    },
    onInputChange() {
      this.handleUpload(this.$refs.fileInput.files);
    },
    async handleUpload(files) {
      this.isUploading = true;
      this.filesData = Array.from(files).map((file) => (
        {
          name: file.name,
          size: file.size,
          status: 'pending',
          errorMessage: null,
        }
      ));

      for (let i = 0; i < files.length; i += 1) {
        try {
          // don't want to generate few thousands of promises
          // eslint-disable-next-line no-await-in-loop
          await this.$api('workout/upload', { file: files[i] });
          this.filesData[i].status = 'success';
        } catch (error) {
          this.filesData[i].status = error.isDuplicate() ? 'warning' : 'error';
          this.filesData[i].errorMessage = error.getMessage();
        }
      }
      this.isUploading = false;
    },
    filesize(size) {
      return filesize(size);
    },
  },
};
</script>
