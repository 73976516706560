<template>
  <section class="app-content">
    <div class="level level-is-shrinkable">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title">Strava Import</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button
              class="button is-primary"
              @click.stop="processImport"
              :disabled="!checkedIds.length || importStarted"
            >
              Import selected
          </button>
        </div>
      </div>
    </div>
    <section v-if="!fetched && !workouts.length">
      Please wait...
    </section>
    <section v-if="fetched && !workouts.length">
      No workouts found.
    </section>
    <section v-else-if="workouts.length">
      <WorkoutImportStatus :items="workouts" />
      <div class="table-container">
        <table class="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>
                  <input
                    ref="selectAll"
                    type="checkbox"
                    @change="toggleAll()"
                    :disabled="importStarted"
                  >
                </th>
                <th>ID</th>
                <th>Name</th>
                <th>Start Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-bind:class="{
                  'has-text-success': workout.status === 'success',
                  'has-text-warning': workout.status === 'warning',
                  'has-text-danger': workout.status === 'error',
                }"
                v-for="(workout, key) in workouts"
                :key="key"
              >
                <td>
                  <input
                    type="checkbox"
                    :value="workout.id"
                    v-model="checkedIds"
                    :disabled="importStarted"
                  >
                </td>
                <td>{{ workout.id }}</td>
                <td>{{ workout.name }}</td>
                <td>{{ workout.startTime }}</td>
                <td v-if="workout.errorMessage">{{ workout.status }}: {{workout.errorMessage}}</td>
                <td v-else>{{ workout.status || '-' }} </td>
              </tr>
            </tbody>
        </table>
      </div>
      <div v-if="hasMore && !importStarted" class="has-text-centered">
        <AsyncButton @handle="handleLoadMore">
          Load More
        </AsyncButton>
      </div>
    </section>
  </section>
</template>

<script>
import AsyncButton from '@/components/AsyncButton.vue';
import WorkoutImportStatus from '@/components/WorkoutImportStatus.vue';

export default {
  name: 'StravaImport',
  components: {
    AsyncButton,
    WorkoutImportStatus,
  },
  data() {
    return {
      workouts: [],
      checkedIds: [],
      importItems: [],
      hasMore: false,
      fetched: false,
      importStarted: false,
      filters: {
        limit: 50,
      },
    };
  },
  async created() {
    this.loadInitialWorkouts();
  },
  methods: {
    async fetchWorkoutsData() {
      this.fetched = false;
      const workoutsData = await this.$api('strava/getWorkouts', this.filters);

      this.fetched = true;
      this.hasMore = workoutsData.results.length === this.filters.limit;

      return workoutsData;
    },
    async loadInitialWorkouts() {
      const workoutsData = await this.fetchWorkoutsData();
      this.workouts = workoutsData.results;
    },
    async handleLoadMore() {
      const [{ startTime }] = this.workouts.slice(-1);
      this.filters.before = startTime;

      const workoutsData = await this.fetchWorkoutsData();
      workoutsData.results.forEach((workout) => this.workouts.push(workout));
    },
    toggleAll() {
      if (this.$refs.selectAll.checked) {
        this.checkedIds = this.workouts.map((workout) => workout.id);
      } else {
        this.checkedIds = [];
      }
    },
    async processImport() {
      this.filterSelectedWorkouts();
      this.importStarted = true;

      for (let i = this.workouts.length - 1; i >= 0; i -= 1) {
        try {
          // don't want to generate few thousands of promises
          // eslint-disable-next-line no-await-in-loop
          await this.$api('strava/sync', this.workouts[i].id);
          this.workouts[i].status = 'success';
        } catch (error) {
          console.log(error);
          this.workouts[i].status = error.isDuplicate() ? 'warning' : 'error';
          this.workouts[i].errorMessage = error.getMessage();
        }
      }

      this.$store.dispatch('sport/fetchAll');
    },
    filterSelectedWorkouts() {
      this.workouts = this.workouts
        .filter((workout) => this.checkedIds.includes(workout.id))
        .map((workout) => ({ ...workout, status: 'pending' }));
    },
  },
};
</script>
